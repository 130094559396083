import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/Auth/LoginView.vue'
import HomeViewVue from '@/views/HomeView.vue'
import CollectView from '@/views/Collection/CollectView.vue'
import DeliverView from '@/views/Deliver/DeliverView.vue'
import SelectTruckView from '@/views/Truck/SelectTruckView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'userHome',
    component: HomeViewVue,
    meta: { requiresAuth: true }
  },
  {
    path: '/collect',
    name: 'collectHome',
    component: CollectView,
    meta: { requiresAuth: true }
  },
  {
    path: '/deliver',
    name: 'deliverHome',
    component: DeliverView,
    meta: { requiresAuth: true }
  },
  {
    path: '/select-truck',
    name: 'selectTruck',
    component: SelectTruckView,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  let isLoggedIn = false;
  let isTokenExpired = false;

  if (user && user["access_token"]) {
    isLoggedIn = true;

    if (user[".expires"]) {
      const expirationDate = new Date(user[".expires"]);
      const currentDate = new Date();

      if (currentDate > expirationDate) {
        isTokenExpired = true;
        sessionStorage.clear();
      }
    }
  }

  // If route requires authentication and user is not logged in or token is expired
  if (to.matched.some(record => record.meta.requiresAuth) && (!isLoggedIn || isTokenExpired)) {
    next({ name: 'login' });
  }
  // If user is already logged in and tries to access the login page
  else if (to.name === 'login' && isLoggedIn && !isTokenExpired) {
    next({ name: 'userHome' });
  } else {
    next();  // Allow to proceed
  }
});

export default router;
