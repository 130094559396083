import { createStore } from 'vuex'

export default createStore({
  state: {
    // api: 'http://localhost:3100/',
    api: 'https://icollect-api.neutralfuels.net/',
    // api: 'http://127.0.0.1:8000/',
    // api: 'http://192.168.68.105:3100/',
    nfToken: null,
    user: null,
    versionNumber: 'V 1.0.1',
  },
  getters: {

  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.nfToken = payload
      console.debug('NF Token State Chnage: ', state.nfToken)
    },
    SET_USER(state, payload) {
      state.user = payload,
        console.debug("NF User State Change: ", state.user)
    }
  },
  actions: {
  },
  modules: {
  }
})
