<template>
  <section class="hero is-fullheight has-background">
    <div class="hero-body">
      <div class="container has-text-centered rounded-container">
        <div class="column is-4 is-offset-4">
          <div class="box">
            <figure class="avatar">
              <img src="../../assets/earth.png" />
            </figure>
            <h3 class="title has-text-black">iCollect Lite</h3>
            <h3 class="subtitle has-text-black">Login</h3>

            <div class="field">
              <div class="control">
                <input
                  class="input is-normal"
                  type="email"
                  placeholder="Email"
                  v-model="email"
                  autofocus=""
                />
              </div>
            </div>

            <div class="field">
              <div class="control has-icons-right position-relative">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="input is-normal"
                  placeholder="Password"
                  v-model="password"
                />
                <i
                  class="fas password-icon"
                  :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                  @click.stop="showPassword = !showPassword"
                ></i>
              </div>
            </div>

            <div
              v-if="error"
              class="field has-text-centered is-size-7 has-text-danger"
            >
              {{ error }}
            </div>

            <button
              class="button is-block is-info is-normal is-fullwidth mt-6 mb-3"
              :class="{ 'is-loading': isPending }"
              @click="login"
            >
              Login
            </button>

            <div
              class="container is-flex is-align-items-center is-justify-content-space-between"
            >
              <h3 class="is-size-7 has-text-black" style="margin-top: 0">
                {{ versionNumber }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
  
  <script >
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const showPassword = ref(false);
    const store = useStore();
    const isPending = ref(false);
    const error = ref(null);
    const router = useRouter();

    const login = async () => {
      const url = store.state.api + "nfLogin";
      try {
        isPending.value = true;

        const postBody = {
          username: email.value,
          password: password.value,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postBody),
        });

        const data = await response.json();

        // Checking if the response contains an access token
        if (data && data[1] && data[1].access_token) {
          console.debug("Login successful! Token:", data[1].access_token);

          // You might want to store the token somewhere like Vuex state or localStorage
          store.commit("SET_TOKEN", data[1].access_token);
          store.commit("SET_USER", data[1]);

          sessionStorage.setItem("token", data[1].access_token);
          sessionStorage.setItem("user", JSON.stringify(data[1]));

          router.push("/select-truck");
        } else {
          // If there's no token in the response, throw an error.
          throw new Error("Failed to login. Please check your credentials.");
        }
      } catch (err) {
        isPending.value = false;
        error.value = err.message;
        console.error(err.message);
      }
    };

    return {
      email,
      password,
      login,
      showPassword,
      isPending,
      error,
      versionNumber: computed(() => store.state.versionNumber),
    };
  },
};
</script>
  
  <style scoped>
/* For the background gradient */
.hero.has-background {
  background: linear-gradient(
    160deg,
    #3498db,
    #2c3e50
  ); /* A gentle gradient from blue to dark blue */
}

/* Updating box styling for consistency */
.box {
  margin-top: 5rem;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* More opacity for better readability */
}

/* Avatar styling remains the same */
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  border-radius: 50%;
  width: 128px;
  height: 128px;
}

/* Text styling for consistency */
.title,
.subtitle {
  color: #333; /* Dark gray text */
  margin-bottom: 1rem;
}

/* Updated input styles for consistency */
.input.is-normal.is-rounded {
  border: 1px solid #e5e7eb; /* Light gray border */
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
}

/* Icon and position styling remains */
.position-relative {
  position: relative;
}
.password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Updated button styling for consistency */
.button.is-info {
  background-color: #3b82f6; /* Blue color */
  border: none;
  margin: 0.5rem 0;
  padding: 0.75rem 1.5rem;
}

.field.has-text-danger {
  color: #ef4444; /* Red color */
  margin: 0.5rem 0;
  text-align: center;
}
</style>