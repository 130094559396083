<template>
  <div class="collect">
    <NavBar :title="' - UCO Collection'" />

    <section class="hero is-fullheight has-background">
      <div class="hero-body">
        <div class="container">
          <!-- <h2 class="title">Collect items</h2> -->

          <div v-if="!validated">
            <div v-if="!scanned" class="box">
              <h2 class="subtitle">
                <i class="fas fa-qrcode"></i>Scan supplier code
              </h2>
              <div class="stream">
                <qr-stream @decode="onDecode"></qr-stream>
              </div>
            </div>
            <div v-else>
              <div class="box">
                <h2 class="subtitle">Please validate scanned supplier</h2>
                <p><strong>Scanned Supplier ID:</strong> {{ supplierId }}</p>
                <p><strong>Scanned Location ID:</strong> {{ locationId }}</p>
              </div>

              <div v-if="errorMessage" class="notification is-danger">
                {{ errorMessage }}
              </div>
              <button
                @click="validateQR"
                class="button is-primary"
                :disabled="loading || errorMessage"
              >
                <span v-if="!loading">Validate</span>
                <span v-else>Loading...</span>
              </button>
              <button
                v-if="errorMessage"
                @click="resetScan"
                class="button is-light ml-2"
              >
                Scan Again
              </button>
            </div>
          </div>

          <div v-if="validated" class="box">
            <div>
              <strong>Supplier Name:</strong> {{ supplierInfo.supplierName
              }}<br />
              <div v-if="supplierInfo.locationName">
                <strong>Location:</strong> {{ supplierInfo.locationName }}
              </div>
            </div>

            <!-- Success message when collection is completed successfully -->
            <div v-if="successMessage" class="notification is-success mt-5">
              <div class="columns">
                <div class="column">{{ successMessage }}</div>
              </div>
              <div class="column">
                <button class="button">
                  <router-link to="/home">Return Home</router-link>
                </button>
              </div>
            </div>

            <div v-else>
              <div class="field mt-5">
                <label class="checkbox">
                  <input type="checkbox" v-model="newDrumDelivery" />
                  New Drum Delivery
                </label>
              </div>

              <div class="field">
                <label class="label">Quantity:</label>
                <div class="control">
                  <input
                    v-model="quantity"
                    type="number"
                    class="input"
                    placeholder="Enter quantity"
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">Supervisor Full Name:</label>
                <div class="control">
                  <input
                    v-model="supervisorName"
                    type="text"
                    class="input"
                    placeholder="Enter Supervisor Full Name"
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">Supervisor Signature:</label>

                <!-- Display signature pad only if the signature is not saved -->
                <vue-signature-pad
                  v-if="!signatureSaved"
                  ref="signaturePadRef"
                  style="width: 100%; height: 200px"
                ></vue-signature-pad>

                <!-- Display the saved signature as an image -->
                <img
                  v-if="signatureSaved"
                  :src="signatureImage"
                  alt="Saved Signature"
                  style="border: 1px solid #ccc; width: 100%; height: 200px"
                />

                <button
                  @click="clearSignature"
                  class="button is-light mt-2 is-small"
                  :disabled="signatureSaved"
                >
                  Clear Signature
                </button>
                <button
                  @click="saveSignature"
                  class="button is-primary mt-2 ml-2 is-small"
                  :disabled="signatureSaved"
                >
                  Save Signature
                </button>

                <!-- Button to delete the saved signature and show the pad again -->
                <button
                  v-if="signatureSaved"
                  @click="deleteSignature"
                  class="button is-danger mt-2 ml-2 is-small"
                >
                  Delete Signature
                </button>
              </div>

              <div v-if="errorMessage" class="notification is-danger">
                {{ errorMessage }}
              </div>

              <button
                @click="completeCollection"
                class="button is-success"
                :disabled="apiCallInProgress"
              >
                <span v-if="!apiCallInProgress">Complete</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer-bar></footer-bar>
  </div>
</template>
  
    
  <script>
import { ref } from "vue";
import NavBar from "@/components/NavBar.vue";
import { QrStream } from "vue3-qr-reader";
import { useStore } from "vuex";
import { VueSignaturePad } from "vue-signature-pad";
import FooterBar from "@/components/FooterBar.vue";

export default {
  components: {
    NavBar,
    QrStream,
    VueSignaturePad,
    FooterBar,
  },
  setup() {
    const scanned = ref(false);
    const validated = ref(false);
    const supplierId = ref("");
    const locationId = ref("");
    const quantity = ref(0);

    const supplierInfo = ref({}); // New ref for supplier info
    const orderId = ref("");
    const errorMessage = ref(null); // New ref for error message

    const signaturePadRef = ref(null);

    const store = useStore();

    const loading = ref(false); // Ref for loading state

    const supervisorName = ref(""); // Ref for supervisor name
    const signatureImage = ref(null); // Ref to store the image data of the signature
    const signatureSaved = ref(false); // Ref to track if the signature is saved

    const newDrumDelivery = ref(false); // ref to track the checkbox state

    const apiCallInProgress = ref(false); // To track if the API call is in progress
    const successMessage = ref(null); // New ref for success message

    const resetScan = () => {
      scanned.value = false;
      errorMessage.value = null;
      supplierId.value = "";
      locationId.value = "";
    };

    function onDecode(data) {
      let fullURL = data;

      if (!data.startsWith("http")) {
        // Add a placeholder base if we only have a query string
        fullURL = "http://placeholder.com/" + data;
      }

      const url = new URL(fullURL);
      const params = url.searchParams;

      supplierId.value = params.get("supplierid");
      locationId.value = params.get("locationid");
      scanned.value = true;

      console.debug(supplierId.value, locationId.value);
    }

    const clearSignature = () => {
      if (signaturePadRef.value) {
        signaturePadRef.value.clearSignature();
      }
    };

    const saveSignature = () => {
      // Get the signature data
      const { isEmpty, data } = signaturePadRef.value.saveSignature();
      if (!isEmpty) {
        signatureImage.value = data; // store the image data
        signatureSaved.value = true; // set the flag to true
      }
    };

    const deleteSignature = () => {
      signatureSaved.value = false;
      signatureImage.value = null;
    };

    const validateQR = async () => {
      loading.value = true; // Set loading to true when starting validation

      const API_URL = store.state.api + "verifySupplier";

      try {
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            supplierId: supplierId.value,
            locationId: parseInt(locationId.value),
          }),
        });

        const result = await response.json();

        if (result[2] === 200 && result[1].valid) {
          supplierInfo.value = result[1].supplier;
          orderId.value = result[1].orderId;
          validated.value = true;
        } else {
          errorMessage.value = result[1].message;
        }
      } catch (error) {
        errorMessage.value = "An error occurred during validation.";
      } finally {
        loading.value = false; // Set loading to false when validation is complete
      }
    };

    const completeCollection = async () => {
      // Grab truckNumber from sessionStorage
      const truckNumber = sessionStorage.getItem("truckNumber");
      const accessToken = sessionStorage.getItem("token");

      // Check if required fields are filled
      if (
        !truckNumber ||
        !supplierId.value ||
        !locationId.value ||
        !supervisorName.value ||
        !signatureImage.value ||
        (!newDrumDelivery.value && !quantity.value) // Updated this condition
      ) {
        errorMessage.value =
          "Please ensure all fields are filled and signature is saved.";
        return;
      }

      // Prepare data for API
      const data = {
        supplierId: supplierId.value,
        locationId: parseInt(locationId.value),
        truckNumber: truckNumber,
        quantity: parseInt(quantity.value),
        supervisorName: supervisorName.value,
        supervisorSignature: signatureImage.value,
        orderId: orderId.value,
        accessToken: accessToken,
        drumDelivery: newDrumDelivery.value,
      };

      const API_URL = store.state.api + "completeUcoCollection";

      try {
        apiCallInProgress.value = true; // Set it true before starting the API call

        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        const result = await response.json();

        if (response.ok) {
          if (result[2] === 200 && result[0] === "Complete") {
            if (newDrumDelivery.value) {
              successMessage.value = `Drum Delivery is recorded successfully.`;
            } else {
              successMessage.value = `Collection Id # ${result[1][0].Id} is recorded successfully.`;
            }
            // validated.value = false; // Hide the form
            errorMessage.value = null; // Clear any error message
          } else {
            errorMessage.value =
              "An error has occurred while completing the request, please contact operator.";
          }
          // Handle successful response
          // Maybe redirect to another view or display a success message
          console.log("Collection completed successfully:", result);
        } else {
          // Handle API errors
          errorMessage.value =
            result.message || "Error completing the collection.";
        }
      } catch (error) {
        errorMessage.value =
          "An error occurred while completing the collection.";
      } finally {
        apiCallInProgress.value = false; // Set it false once API call is complete
      }
    };

    return {
      scanned,
      validated,
      supplierId,
      locationId,
      quantity,
      onDecode,
      validateQR,
      completeCollection,
      supplierInfo,
      errorMessage,
      loading,
      supervisorName,
      signatureImage,
      deleteSignature,
      signatureSaved,
      resetScan,
      clearSignature,
      saveSignature,
      signaturePadRef,
      newDrumDelivery,
      apiCallInProgress,
      successMessage,
    };
  },
};
</script>
  
<style scoped>
/* Background gradient similar to Home and Select Truck screens */
.hero.has-background {
  background: linear-gradient(
    160deg,
    #3498db,
    #2c3e50
  ); /* A gentle gradient from blue to dark blue */
}

/* Centered content with consistent margins */
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
}

/* Consistent text styling */
.title,
.subtitle {
  color: #333; /* Dark gray text */
  margin-bottom: 1rem;
}

.subtitle i {
  margin-right: 10px;
}

/* Consistent button styling */
.button.is-primary,
.button.is-success {
  background-color: #3b82f6; /* Blue color */
  border: none;
  margin: 0.5rem 0;
  padding: 0.75rem 1.5rem;
}

.button.is-light {
  background-color: #e5e7eb; /* Light gray */
  border: none;
  margin: 0.5rem 0;
  padding: 0.75rem 1.5rem;
  color: #333; /* Dark gray text */
}

.button.is-danger {
  background-color: #ef4444; /* Red color */
  border: none;
  margin: 0.5rem 0;
  padding: 0.75rem 1.5rem;
}

/* Notification styling */
.notification {
  margin: 1rem 0;
}

/* Input and form styling for consistency */
.input,
.checkbox {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #e5e7eb; /* Light gray border */
}

.label {
  margin-top: 1rem;
}

/* Signature pad styling */
.vue-signature-pad {
  border: 1px solid #e5e7eb; /* Light gray border */
  margin-top: 1rem;
}
</style>

  