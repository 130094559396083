<template>
  <div class="home">
    <NavBar :title="''" />

    <!-- Main content for HomeView -->
    <section class="hero is-fullheight has-background">
      <div class="hero-body">
        <div class="container">
          <!-- User Info Box -->
          <div class="box user-info">
            <h2 class="title">
              <i class="fas fa-user"></i> Welcome, {{ storedUser.firstname }}
              {{ storedUser.lastname }}
            </h2>
            <p>
              <i class="fas fa-truck"></i> Current truck number:
              {{ truckNumber }}
            </p>
            <p>
              <i class="fas fa-oil-can"></i> Total UCO Collected:
              {{ totalUcoCollected }}
            </p>
            <p>
              <i class="fas fa-tasks"></i> Pending Deliveries:
              {{ pendingDeliveries }}
            </p>
          </div>

          <!-- Buttons inside a Bulma column -->
          <div class="columns action-buttons">
            <div class="column">
              <button
                class="button box is-primary is-fullwidth"
                @click="navigate('collectHome')"
              >
                <h2 class="title">Collect</h2>
              </button>
            </div>
            <div class="column">
              <button
                class="button box is-warning is-fullwidth"
                @click="navigate('deliverHome')"
              >
                <h2 class="title">Deliver</h2>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer-bar></footer-bar>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import { useStore } from "vuex";

export default {
  name: "HomeView",
  components: {
    NavBar,
    FooterBar,
  },
  setup() {
    const userString = sessionStorage.getItem("user");
    const totalUcoCollected = ref(0);
    const pendingDeliveries = ref(0);

    const store = useStore();

    let storedUser = null;

    if (userString) {
      try {
        storedUser = JSON.parse(userString);
      } catch (error) {
        console.error("Error parsing user from session storage:", error);
      }
    }
    const truckNumber = sessionStorage.getItem("truckNumber");
    const accessToken = sessionStorage.getItem("token");

    const router = useRouter();

    const API_URL = store.state.api + "getPendingDirectPumpOut";

    const fetchData = async () => {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          truckNumber: truckNumber,
          accessToken: accessToken,
        }),
      });

      const data = await response.json();

      if (data && data[0] === "Complete" && data[2] === 200) {
        totalUcoCollected.value = data[1].totalucocollected;
        pendingDeliveries.value = data[1].pendingdeliveries;
      } else {
        console.error("Error fetching or parsing data from the server.");
      }
    };

    fetchData();

    const navigate = (routeName) => {
      router.push({ name: routeName });
    };

    return {
      storedUser,
      truckNumber,
      navigate,
      totalUcoCollected,
      pendingDeliveries,
    };
  },
};
</script>

<style scoped>
.hero.has-background {
  background: linear-gradient(
    160deg,
    #3498db,
    #2c3e50
  ); /* A gentle gradient from blue to dark blue */
}

.title,
.subtitle,
p {
  color: grey;
}

.box {
  margin-bottom: 30px;
  padding: 20px 40px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: rgba(
    255,
    255,
    255,
    0.9
  ); /* Semi-transparent white for a frosted glass effect */
}

.user-info {
  text-align: center;
}

.user-info i {
  margin-right: 10px;
}

.action-buttons .button {
  font-size: 1.5em;
  padding: 20px 0;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.action-buttons .button:hover {
  transform: scale(1.05);
}
</style>
