<template>
  <div class="app-container">
    <router-view />
  </div>
</template>

<style>
.app-container {
  position: relative; /* This ensures the fixed gradient background is contained to this element */
}
</style>
