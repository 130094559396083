<template>
  <div>
    <NavBar :title="' - Select Truck'" />

    <section class="hero is-fullheight has-background">
      <div class="hero-body">
        <div class="container">
          <!-- Camera Stream inside a Bulma Box -->
          <div v-if="!scanned" class="box content-section">
            <h2 class="subtitle">
              <i class="fas fa-qrcode"></i> Scan truck code
            </h2>
            <div class="stream">
              <qr-stream @decode="onDecode">
                <div class="frame"></div>
              </qr-stream>
            </div>
          </div>

          <!-- Scanned Data inside a Bulma Box -->
          <div v-else>
            <div class="box content-section">
              <strong
                ><i class="fas fa-truck"></i> Scanned Truck Number:</strong
              >
              {{ scannedTruckNumber }}
            </div>
            <p v-if="error" class="has-text-danger">{{ error }}</p>
            <button
              @click="confirmTruck"
              :disabled="isConfirmDisabled"
              class="button is-primary action-button mr-2"
            >
              <span v-if="isLoading">Loading...</span>
              <span v-else>Confirm Truck</span>
            </button>
            <button @click="rescan" class="button is-warning action-button">
              Scan Again
            </button>
          </div>
        </div>
      </div>
    </section>

    <footer-bar></footer-bar>
  </div>
</template>
  
    
<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import NavBar from "@/components/NavBar.vue";
import { QrStream } from "vue3-qr-reader";
import { useStore } from "vuex";
import FooterBar from "@/components/FooterBar.vue";

export default {
  components: {
    NavBar,
    QrStream,
    FooterBar,
  },
  setup() {
    const scannedTruckNumber = ref("");
    const scanned = ref(false);
    const router = useRouter();
    const store = useStore();

    const isLoading = ref(false);
    const error = ref("");

    const isConfirmDisabled = computed(
      () => isLoading.value || error.value !== ""
    );

    function onDecode(data) {
      console.debug(data);
      scannedTruckNumber.value = data;
      scanned.value = true; // Stop scanning once a QR code is detected.
    }

    const confirmTruck = async () => {
      isLoading.value = true;
      error.value = "";

      const API_URL = store.state.api + "checkTruck";
      const userString = sessionStorage.getItem("user");
      const user = JSON.parse(userString);
      const siteId = user.siteid;

      const body = JSON.stringify({
        truckNumber: scannedTruckNumber.value,
        siteId: siteId,
      });

      console.debug(API_URL, body);

      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      const data = await response.json();

      if (data[1]) {
        // Checks if the second item in the response is true
        sessionStorage.setItem("truckNumber", scannedTruckNumber.value); // Store the truck number in session storage
        router.push("/home"); // example of navigating to the home screen
      } else {
        // Handle the case when the truck number and site ID are not valid
        error.value = "Invalid Truck Number";
        console.error("Invalid truck number or site ID.");
      }
    };

    const rescan = () => {
      scanned.value = false;
      scannedTruckNumber.value = "";
      error.value = "";
      isLoading.value = false;
    };

    return {
      scannedTruckNumber,
      scanned,
      confirmTruck,
      onDecode,
      rescan,
      isLoading,
      error,
      isConfirmDisabled,
    };
  },
};
</script>
<style scoped>
.hero.has-background {
  background: linear-gradient(
    160deg,
    #3498db,
    #2c3e50
  ); /* A gentle gradient from blue to dark blue */
}

.subtitle,
strong,
p {
  color: grey;
}

.box {
  margin-bottom: 30px;
  padding: 20px 40px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: rgba(
    255,
    255,
    255,
    0.9
  ); /* Semi-transparent white for a frosted glass effect */
}

.content-section {
  text-align: center;
}

.content-section i {
  margin-right: 10px;
}

.action-button:hover {
  transform: scale(1.05);
}
</style>
  