<template>
  <NavBar :title="' - UCO Delivery'" />
  <section class="hero is-fullheight has-background">
    <div class="hero-body">
      <div class="container has-text-centered rounded-container">
        <div class="column is-6 is-offset-3">
          <div class="box delivery-box">
            <div v-if="!deliveryCompleted">
              <!-- Total Delivered Quantity Field -->
              <div class="field">
                <label class="label">Total Delivered Quantity</label>
                <div class="control">
                  <input
                    class="input is-normal"
                    type="number"
                    placeholder="Enter Quantity"
                    v-model="quantity"
                  />
                </div>
              </div>

              <!-- Comments Field -->
              <div class="field">
                <label class="label">Comments</label>
                <div class="control">
                  <textarea
                    class="textarea is-normal"
                    placeholder="Enter Comments"
                    v-model="comments"
                  ></textarea>
                </div>
              </div>

              <div class="field is-grouped">
                <label class="label mr-3">ID</label>
                <div class="control is-expanded">
                  <input
                    class="input is-normal"
                    type="password"
                    placeholder="Enter SignOff ID"
                    v-model="signOffId"
                  />
                </div>
                <div class="control">
                  <button
                    class="button is-info"
                    @click="validateId"
                    :disabled="employeeName"
                  >
                    Validate
                  </button>
                </div>
              </div>

              <!-- Display employee name after validation -->
              <div v-if="employeeName" class="field">
                <label class="label">Employee Name</label>
                <div class="control">
                  <p>{{ employeeName }}</p>
                </div>
              </div>

              <div v-if="errorMessage" class="notification is-danger">
                {{ errorMessage }}
              </div>

              <!-- Accept Delivery Button -->
              <div class="field">
                <button
                  class="button is-info is-fullwidth"
                  :disabled="!employeeName"
                  @click="acceptDelivery"
                >
                  Accept Delivery
                </button>
              </div>
            </div>

            <div v-else class="notification is-success mt-5">
              <p>Your Delivery ID is: {{ deliveryId }}</p>
              <router-link to="/home">
                <button class="button is-info is-fullwidth mt-5">
                  Return Home
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <FooterBar></FooterBar>
</template>
  
  
  
<script>
import { ref, computed } from "vue";
import NavBar from "@/components/NavBar.vue"; // Assuming you have a NavBar component
import { useStore } from "vuex";
import FooterBar from "@/components/FooterBar.vue";

export default {
  components: {
    NavBar,
    FooterBar,
  },
  setup() {
    const quantity = ref(null);
    const comments = ref("None");
    const signOffId = ref("");
    const employeeName = ref(null);
    const staffId = ref(null);

    const deliveryCompleted = ref(false);
    const deliveryId = ref(null);

    const errorMessage = ref(null);

    const store = useStore();

    // Check if the employeeName exists to enable the button
    const isEmployeeValidated = computed(() => employeeName.value !== null);

    // Grab truckNumber from sessionStorage
    const truckNumber = sessionStorage.getItem("truckNumber");
    const accessToken = sessionStorage.getItem("token");
    const userString = sessionStorage.getItem("user");
    const user = JSON.parse(userString);
    const siteId = user.siteid;

    const loading = ref(false);

    const validateId = async () => {
      const API_URL = store.state.api + "checkEmployeeId";
      errorMessage.value = null;
      if (!signOffId.value.trim()) {
        errorMessage.value = "Please enter the SignOff ID.";
        return; // stop further execution if ID is not provided
      }
      try {
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            siteId: siteId,
            employeeNumber: parseInt(signOffId.value),
          }),
        });

        const result = await response.json();

        // Checking for valid employee
        if (result[1].staffid) {
          employeeName.value = `${result[1].firstname} ${result[1].lastname}`;
          staffId.value = result[1].staffid;
        } else {
          employeeName.value = null;
          // If the ID is invalid, show an error notification
          errorMessage.value = result[1].message;
          //   showErrorNotification(result[1].message); // Assume this function shows a notification to the user
        }
      } catch (err) {
        console.error(err);
        // If there's an error while fetching, show a generic error
        // showErrorNotification("An error occurred while validating the ID.");
        errorMessage.value = result[1].message;
      }
    };

    const showErrorNotification = (message) => {
      alert(message);
    };

    const acceptDelivery = async () => {
      loading.value = true; // Start loading
      errorMessage.value = null;
      if (!quantity.value || !comments.value || !staffId.value) {
        errorMessage.value = "Please ensure all fields are filled in.";
        return; // stop further execution if any required field is empty
      }
      const DELIVERY_API_URL = store.state.api + "completeUcoBulkDelivery";

      // Prepare the payload
      const payload = {
        staffid: staffId.value,
        totaldelivered: quantity.value,
        truckNumber: truckNumber,
        accessToken: accessToken,
        comments: comments.value,
      };

      try {
        const response = await fetch(DELIVERY_API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const responseData = await response.json();

        if (response.status === 200 && responseData[0] === "Complete") {
          deliveryCompleted.value = true;
          deliveryId.value = responseData[1].PostResults[0].Id;
        } else {
          errorMessage.value = "Error occurred while accepting the delivery.";
        }
      } catch (error) {
        console.error("Error in acceptDelivery:", error);
        errorMessage.value = "Error occurred while accepting the delivery.";
      } finally {
        loading.value = false; // End loading
      }
    };

    return {
      quantity,
      comments,
      signOffId,
      employeeName,
      validateId,
      acceptDelivery,
      isEmployeeValidated,
      errorMessage,
      deliveryCompleted,
      deliveryId,
      loading,
    };
  },
};
</script>

  
<style scoped>
/* Background gradient similar to Home and Select Truck screens */
.hero.has-background {
  background: linear-gradient(
    160deg,
    #3498db,
    #2c3e50
  ); /* A gentle gradient from blue to dark blue */
}

/* Centered content with consistent margins */
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
}

/* Consistent text styling */
.title,
.subtitle {
  color: #333; /* Dark gray text */
  margin-bottom: 1rem;
}

.subtitle i {
  margin-right: 10px;
}

.control {
  display: flex;
  align-items: center; /* center the children vertically */
}

.field.is-grouped > .label {
  margin-bottom: 0.5rem; /* adjust this value based on your design preference */
}

/* Consistent button styling */
.button.is-primary,
.button.is-success {
  background-color: #3b82f6; /* Blue color */
  border: none;
  margin: 0.5rem 0;
  padding: 0.75rem 1.5rem;
}

.button.is-light {
  background-color: #e5e7eb; /* Light gray */
  border: none;
  margin: 0.5rem 0;
  padding: 0.75rem 1.5rem;
  color: #333; /* Dark gray text */
}

.button.is-danger {
  background-color: #ef4444; /* Red color */
  border: none;
  margin: 0.5rem 0;
  padding: 0.75rem 1.5rem;
}

/* Notification styling */
.notification {
  margin: 1rem 0;
}

/* Input and form styling for consistency */
.input,
.checkbox {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #e5e7eb; /* Light gray border */
}

.label {
  margin-top: 1rem;
}
</style>

  