<!-- NavBar.vue -->
<template>
  <nav class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a class="navbar-item" href="#">
        <img
          src="@/assets/earth.png"
          alt="iCollect Logo"
          width="28"
          height="28"
        />
        <span class="title is-4 ml-2">iCollect {{ title }}</span>
      </a>
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        @click="toggleMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': isActive }">
      <div class="navbar-end">
        <router-link class="navbar-item" to="/home">Home</router-link>
        <a class="navbar-item has-text-danger" @click="logout">Logout</a>
      </div>
    </div>
  </nav>
</template>
  
  <script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isActive = ref(false);
    const store = useStore();
    const router = useRouter();

    const toggleMenu = () => {
      isActive.value = !isActive.value;
    };

    const logout = () => {
      // Clearing the session storage
      sessionStorage.clear();

      // Setting the store values to null
      store.commit("SET_TOKEN", null);
      store.commit("SET_USER", null);

      // Navigate to login
      router.push({ name: "login" });
    };

    return {
      toggleMenu,
      logout,
      isActive,
    };
  },
};
</script>
  
  <!-- Style section remains the same. -->
  